<template>
  <div class="conversion_conten_p">
    <div class="content_top">
      <div class="content_box">
        <img :src="tableData.user.user_avatar" />
        <div>
          <div class="title_c">{{tableData.user.user_name}}</div>
          <div style="display:flex;">
            <div class="vipImg">
              <img style="width:45px;height:23px;margin:7px 5px 0 0" src="../../assets/lador_leve.png" />
              <div class="vip_text">V{{tableData.user.member.vip_level}}</div>
            </div>
            <img class="lador_icon" v-if="tableData.user.union_member!=0" src="../../assets/ladoricon.png" alt="">
            <img class="lador_icon" v-if="tableData.user.union_shareholder!=0" src="../../assets/gufen.png" alt="">
          </div>
          <div class="text_c">{{tableData.user.union_expire_at.split(" ")[0]}}(工会会员到期)</div>
        </div>
        <div class="price_c">
          <div>{{tableData.user.member.available_assets}}</div>
          <div class="btn">补金</div>
        </div>
      </div>
    </div>

    <div class="text_color">*兑换增值工分将会产出新的工分合同，请留意审核流程。</div>

    <div class="style_size">请选择兑换方式:</div>
    <div class="item_list" style="margin-bottom:20px">
      <van-radio-group v-model="checked1" style="display:flex;">
        <!-- <van-radio checked-color="#DAB274" name='1' icon-size="24px">
          <div class="selectText">分享式</div>
        </van-radio> -->
        <div v-if="configData.shareholder_activity">
          <van-radio v-if="configData.shareholder_activity.activity_assets_2!=0&&configData.shareholder_activity.shares_cardinal_2!=0" checked-color="#DAB274" style="margin-left:30px" name='2' icon-size="24px">
            <div class="selectText">置换式</div>
          </van-radio>
        </div>
        <div v-else>
          <van-radio v-if="configData.config.shares_apply_assets_2!=0&&configData.config.shares_apply_cardinal_2!=0" checked-color="#DAB274" style="margin-left:30px" name='2' icon-size="24px">
            <div class="selectText">置换式</div>
          </van-radio>
        </div>

        <van-radio checked-color="#DAB274" v-if="freightData.shares_apply_increase_activity==1&&configData.shareholder_increase_activity" style="margin-left:30px" name='3' icon-size="24px">
          <div class="selectText">增值式</div>
        </van-radio>
      </van-radio-group>
    </div>
    <div v-if="configData.shareholder_activity">
      <div class="rule_c" v-if="checked1==1">
        <div class="style_size1">兑换说明:</div>
        <div>1.超出{{configData.shareholder_activity.activity_assets_1}}补金的部分才可以进行兑换</div>
        <div>2.在时间{{configData.shareholder_activity.activity_start.split(" ")[0]}}-{{configData.shareholder_activity.activity_end.split(" ")[0]}}日内，分享人数≥{{configData.shareholder_activity.activity_inviter_1}}人</div>
        <div>3.兑换比例{{configData.shareholder_activity.shares_cardinal_1}}补金=1工分</div>
        <div>4.兑换额度最低不得小于{{freightData.shares_apply_min_amount}}</div>
      </div>
      <div class="rule_c" v-else-if="checked1==2">
        <div class="style_size1">兑换说明:</div>
        <div>1.超出{{configData.shareholder_activity.activity_assets_2}}补金的部分才可以进行兑换</div>
        <div>2.在时间{{configData.shareholder_activity.activity_start.split(" ")[0]}}-{{configData.shareholder_activity.activity_end.split(" ")[0]}}日内，分享人数≥{{configData.shareholder_activity.activity_inviter_2}}人</div>
        <div>3.兑换比例{{configData.shareholder_activity.shares_cardinal_2}}补金=1工分</div>
        <div>4.兑换额度最低不得小于{{freightData.shares_apply_min_amount}}</div>
      </div>
    </div>
    <div v-else>
      <div class="rule_c" v-if="checked1==1">
        <div class="style_size1">兑换说明:</div>
        <div>1.超出{{configData.config.shares_apply_assets_1}}补金的部分才可以进行兑换</div>
        <div>2.分享人数≥{{configData.config.shares_apply_inviter_1}}人</div>
        <div>3.兑换比例{{configData.config.shares_apply_cardinal_1}}补金=1工分</div>
        <div>4.兑换额度最低不得小于{{freightData.shares_apply_min_amount}}</div>
      </div>
      <div class="rule_c" v-else-if="checked1==2">
        <div class="style_size1">兑换说明:</div>
        <div>1.超出{{configData.config.shares_apply_assets_2}}补金的部分才可以进行兑换</div>
        <div>2.分享人数≥{{configData.config.shares_apply_inviter_2}}人</div>
        <div>3.兑换比例{{configData.config.shares_apply_cardinal_2}}补金=1工分</div>
        <div>4.兑换额度最低不得小于{{freightData.shares_apply_min_amount}}</div>
      </div>
    </div>

    <div class="rule_c" v-if="checked1==3">
      <div class="style_size1">兑换说明:</div>
      <!-- <div>1.超出{{configData.shareholder_increase_activity.activity_assets}}补金的部分才可以进行兑换</div> -->
      <!-- {{configData.shareholder_increase_activity.activity_start.split(" ")[0]}}- -->
      <div>1.该兑换方式于{{configData.shareholder_increase_activity.activity_end.split(" ")[0]}}日结束</div>
      <!-- <div>3.兑换比例{{configData.shareholder_increase_activity.activity_assets}}+1,{{configData.shareholder_increase_activity.activity_assets}}补金+1元 = 1工分</div>-->
      <div>2.兑换额度最低不得小于{{freightData.shares_apply_min_amount}}</div>
    </div>
    <div v-if="checked1==3">
      <!--<div class="style_size" v-if="configData.shareholder_increase_activity.activity_assets">当前可兑换最大工分:<span>{{Math.floor(shareData)}}</span></div>-->
    </div>
    <div class="style_size" v-else>当前可兑换最大工分:<span>{{Math.floor(shareData)}}</span></div>
    <div class="item_text">
      <div class="style_size1">请输入兑换额度:</div>
      <input placeholder="请输入兑换工分" v-model="shareNumber" />
      <div v-if="checked1!=3" class="olny" @click="onlyBtn">全部</div>
    </div>
    <div style="display:flex" class="item_list">
      <div style="color:#DBB376">运费：{{freightData.shares_apply_shipping_fee}}</div>
      <!-- <div style="color:#FF0000;margin:0 20px">支付状态：待支付</div> -->
      <!-- <div style="color:#3377FF">去支付></div> -->
    </div>

    <div style="display:flex;margin-top:63px;margin-bottom:63px">
      <div v-if="iconShow==false" @click="checkoutBtn(1)" class="select_box"></div>
      <div v-else class="select_box" @click="checkoutBtn(2)" style="background:#87CEFA"></div>
      <div class="ruleBtn" @click="ruleBtn">我已熟知与查看《工会股东协议》</div>
    </div>
    <!-- <div class="offine_text" style="color:#fff;">您已是会员用户,只能激活股东</div> -->
    <!-- <div class="offine_text"  style="color:#fff;">如不是请<span style="color:#DEB77A">申请股东></span></div> -->
    <div class="office_submit" @click="officeSubmitBtn">
      提交审核
    </div>

    <toast v-if="toastShow" :config="configData"></toast>
    <!-- 签字弹窗 -->
    <div class="signtureShow" v-if="signtureShow" @click="signtureShow">
      <div class="signtureShow_box">
        <catSignature :imgWidth="imgWidth" @signatureBtn="signatureBtn" @cloneBtn="cloneBtn1" :imgToken="imgToken" @cloneImg="cloneImg" @imgUblod="imgUblod"></catSignature>
      </div>
    </div>

    <!-- 选择推荐人 -->
    <div class="pop_present" v-if="presentShow">
      <div class="present_box">
        <div style="text-align: center;font-size:20px;margin-bottom:20px;margin-left:40px">选择推荐人<span class="cloneBtn" @click="presentShow=false">X</span></div>
        <div style="color:#3078FF;border-bottom:1px solid #eee;padding-bottom:10px">当前推荐人：{{referrerMember}}</div>
        <div class="selectStatus1">
          <van-radio-group class="selectList" @change="selectBtn" v-model="radioValue" icon-size="18px">
            <van-radio class="radioClass" style="margin-top:20px" v-for="(item,index) in referrerDeatil" :key="index" checked-color="#4EB84A" :name="index">{{item.referrer_name}}<span style="margin-left:20px">{{item.referrer_phone}}</span><span class="confim" v-if="item.is_preference==1">{{item.is_preference==1?'默认':''}}</span></van-radio>
          </van-radio-group>
        </div>
        <div class="item_rerr">
          <div style="border-right:1px solid #eee" @click="presentShow=false">不选择推荐人</div>
          <div style="color:#FF2128" @click="comfimBtn">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  dealDetails_api,
  conversionConfig_api,
  conversionSunmit_api,
  getConfig_api,
  referrerDeatil_api
} from "@/api/deal";
import * as apiCommon from "@/api/common";
import wx from "weixin-js-sdk";
import catSignature from "../../components/catSignature.vue";
import toast from "@/components/toast";
// import store from "../../store/index.js";
export default {
  data() {
    return {
      checked1: "3",
      iconShow: false,
      tableData: {
        user: {
          member: {},
          union_expire_at: ""
        }
      },
      configData: {
        config: {}
      },
      signtureShow: false,
      imgWidth: 645,
      imgToken: "",
      img: "",
      shareData: "",
      shareNumber: "",
      nameImg: "",
      toastShow: false,
      freightData: {},
      // 节流
      submitBtn: true,
      radioValue: 1,
      referrerDeatil: [],
      referrerMember: "",
      presentShow: false,
      presentData: null,
      cloneShow: false
    };
  },
  created() {
    document.title = "增值工分";
    this.getHome();
    // 获取兑换配置
    this.config();
    // 获取兑换运费
    this.getfreight();
    this.getToken();
    this.getreferrr();
  },
  components: {
    catSignature,
    toast
  },
  watch: {
    checked1(oldValue, newValue) {
      console.log(oldValue, newValue);
      this.price();
    }
  },
  methods: {
    selectBtn(row) {
      console.log(row);
      this.presentIndex = row;
    },
    comfimBtn() {
      this.presentShow = false;
      this.presentData = this.referrerDeatil[this.presentIndex];
    },
    addBtn() {
      this.presentShow = true;
    },
    getreferrr() {
      referrerDeatil_api({ page: 1, limit: 10 }).then(res => {
        if (res.code == 0) {
          console.log(res);
          this.referrerDeatil = res.data;
          this.referrerDeatil.concat(res.data);
          this.referrerDeatil.forEach(e => {
            this.referrerDeatil.push(e);
            if (e.is_preference == 1) {
              this.referrerMember = e.referrer_name;
            }
          });
        }
      });
    },
    getfreight() {
      let data = {
        config: [
          "shares_apply_shipping_fee",
          "shares_apply_min_amount",
          "shares_apply_increase_activity"
        ]
      };
      getConfig_api(data).then(res => {
        if (res.code == 0) {
          this.freightData = res.data;
        }
      });
    },
    cloneBtn1() {
      this.signtureShow = false;
    },
    async getToken() {
      console.log(2);
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
      this.imgToken = res.data;
    },
    checkoutBtn(index) {
      console.log(index);
      if (index == 1) {
        this.signtureShow = true;
        this.iconShow = true;
      } else {
        this.iconShow = false;
      }
    },
    onlyBtn() {
      this.shareNumber = Math.floor(this.shareData);
      this.$forceUpdate();
    },
    // 获取配置
    config() {
      conversionConfig_api().then(res => {
        if (res.code == 0) {
          this.configData = res.data;

          this.price(1);
        }
      });
    },
    price(index) {
      if (this.configData.shareholder_activity) {
        if (this.checked1 == 1) {
          this.shareData =
            (Number(this.configData.user.member.available_assets) -
              Number(this.configData.shareholder_activity.activity_assets_1)) /
            Number(this.configData.shareholder_activity.shares_cardinal_1);
        } else if (this.checked1 == 2) {
          this.shareData =
            (Number(this.configData.user.member.available_assets) -
              Number(this.configData.shareholder_activity.activity_assets_2)) /
            Number(this.configData.shareholder_activity.shares_cardinal_2);
        }
      } else {
        if (this.checked1 == 1) {
          this.shareData =
            (Number(this.configData.user.member.available_assets) -
              Number(this.configData.config.shares_apply_assets_1)) /
            this.configData.config.shares_apply_cardinal_1;
        } else if (this.checked1 == 2) {
          this.shareData =
            (Number(this.configData.user.member.available_assets) -
              Number(this.configData.config.shares_apply_assets_2)) /
            this.configData.config.shares_apply_cardinal_2;
          console.log(321312);
        }
      }
      if (this.checked1 == 3) {
        this.shareData =
          this.configData.user.member.available_assets /
          this.configData.shareholder_increase_activity.shares_cardinal;
        if (this.configData.user.member.available_assets == 0) {
          this.shareData = 1;
        }
      }
      if (index != 1) {
        if (this.checked1 == 3) {
          this.shareNumber = "";
        } else {
          this.shareNumber = Math.floor(this.shareData);
        }
      }
      console.log(this.shareData);
    },
    getHome() {
      dealDetails_api().then(res => {
        console.log(res);
        if (res.code == 0) {
          this.tableData = res.data;
          // if (!this.tableData.user.union_info.bank) {
          //   this.$toast("请补全银行卡信息");
          //   setTimeout(() => {
          //     this.$router.push("../deal/mine");
          //   }, 1000);

          //   return false;
          // }
          this.$forceUpdate();
        }
      });
    },
    officeSubmitBtn() {
      this.submit();
    },
    // 数据提交
    submit() {
      let data = {
        apply_type: Number(this.checked1),
        shares_amount: Math.floor(this.shareNumber),
        referrer_phone: this.presentData ? this.presentData.referrer_phone : ""
      };
      if (this.checked1 == 3) {
        if (
          this.configData.shareholder_increase_activity.activity_assets != 0
        ) {
          if (Math.floor(this.shareData) < this.shareNumber) {
            this.$toast("不能大于最大金额");
            return false;
          }
        }
      }

      if (this.submitBtn == false) {
        return false;
      }
      this.submitBtn = false;
      conversionSunmit_api(data).then(res => {
        this.submitBtn = true;
        if (res.code == 0) {
          this.$router.push(`./orderDetails?id=` + res.data.apply_id);
        } else {
          this.$toast(res.error);
        }
      });
    },
    payBtn(res) {
      wx.chooseWXPay({
        timestamp: res.data.pay_info.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: res.data.pay_info.nonceStr, // 支付签名随机串，不长于 32 位
        package: res.data.pay_info.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: "MD5", // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: res.data.pay_info.paySign, // 支付签名
        success(res) {
          if (res.errMsg == "chooseWXPay:ok") {
            this.$toast("支付成功");
            this.$router.push(`./orderDetails?id=` + res.data.apply_id);
          } else {
            alert(res.errMsg);
            this.$toast("支付失败", "middle");
          }
        },
        cancel: function(res) {
          this.$toast("支付取消", "middle", res);
        }
      });
    },
    ruleBtn() {
      this.$router.push("../shareholders/rule");
    },
    signatureBtn() {
      this.toastShow = true;
      this.configData.title = "签名不能为空";
      setTimeout(e => {
        console.log(e);
        this.toastShow = false;
      }, 1000);
    },
    imgUblod(img) {
      console.log(img, "1111111111");
      this.signtureShow = false;
      this.iconShow = true;
      this.nameImg = img;
      // if(store.state.URL=="https://test2.jdcloud.vip/"){
      //   alert(img)
      // }
      this.toastShow = true;
      this.configData.title = "签名完成";
      setTimeout(() => {
        this.toastShow = false;
      }, 1000);
      this.popShow = true;
    }
  }
};
</script>
<style>
.van-toast {
  width: 300px;
  min-height: 120px;
}
.van-toast__text {
  font-size: 35px;
}
.van-icon {
  font-size: 40px;
}
.van-dialog {
  width: 500px;
  font-size: 35px !important;
}
.van-dialog__message {
  font-size: 25px;
  line-height: 40px;
}
.content_top {
  width: 750px;
  height: 317px;
  background: #2e2e30;
  opacity: 1;
  border-radius: 0 0 100px 100px;
  position: relative;
}
.content_box {
  width: 726px;
  height: 300px;
  display: flex;
  background-image: linear-gradient(120deg, #f0d099, #dab274);
  position: absolute;
  top: 70px;
  left: 12px;
  border-radius: 30px;
  padding: 20px;
  box-sizing: border-box;
}
.content_box > img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-right: 20px;
}
.list_img img {
  width: 95px;
  height: 95px;
  /* margin: 18px 21px; */
}

.vipImg {
  position: relative;
}
.vip_text {
  position: absolute;
  left: 45px;
  top: 30px;
  font-size: 18px;
  color: #d4b479;
}
.lador_icon {
  width: 46px;
  height: 46px;
  margin-top: 13px;
  margin-right: 15px;
}
.title_c {
  font-size: 35px;
  color: #563b15;
}
.text_c {
  font-size: 25px;
  color: #563b15;
  margin-top: 120px;
  margin-left: -100px;
}
.price_c {
  position: absolute;
  bottom: 50px;
  right: 65px;
  text-align: center;
}
.price_c {
  font-size: 60px;
  color: #fff;
}
.price_c .btn {
  width: 120px;
  height: 45px;
  background: #fef8ec;
  opacity: 1;
  border-radius: 5px;
  font-size: 25px;
  color: #563b15;
  text-align: center;
  line-height: 45px;
  margin-top: 30px;
}
.conversion_btn {
  margin-top: 100px;
  width: 160px;
  height: 59px;
  line-height: 59px;
  background: linear-gradient(180deg, #eed09d 0%, #e4c085 100%);
  box-shadow: 0px 0px 15px rgba(223, 183, 136, 0.35);
  opacity: 1;
  border-radius: 10px;
  color: #fff;
  font-size: 25px;
  text-align: center;
  margin-left: 12px;
}
.conversion_list {
  width: 629px;
  margin: 0 auto;
}
.conversion_item {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 25px;
}
.conversion_item div {
  margin-top: 10px;
}
.status {
  flex-direction: column;
  justify-content: space-between;
}
.conversion_item .details {
  margin-top: 47px;
}
.text_color {
  text-align: center;
  margin: 100px 0 20px;
  font-size: 25px;
  color: #563b15;
}
.selectText {
  color: #563b15;
  font-size: 24px;
}
.style_size {
  font-size: 25px;
  color: #2e2e30;
  margin: 45px 0 37px 31px;
  font-weight: 800;
}
.style_size span {
  font-size: 50px;
  font-weight: 800;
  margin-left: 45px;
}
.rule_c .style_size1 {
  font-size: 25px;
  color: #2e2e30;
  margin: 45px 0 37px 0px;
  font-weight: 800;
}
.item_text .style_size1 {
  font-size: 25px;
  color: #2e2e30;
  margin: 45px 0 37px 0px;
  font-weight: 800;
  display: flex;
}
.item_list {
  margin-left: 140px;
}
.rule_c {
  margin-left: 31px;
  color: #909090;
  font-size: 24px;
}
.rule_c div {
  margin-left: 100px;
  margin-bottom: 10px;
}
.item_text {
  display: flex;
  margin-left: 31px;
}
.item_text input {
  border: none;
  width: 305px;
  height: 80px;
  border-bottom: 2px solid #563b15;
  opacity: 1;
  margin-left: 31px;
  font-size: 40px
}
.olny {
  font-size: 25px;
  color: #3377ff;
  margin: 50px 0 0 20px;
}
.item_list {
  margin: 40px 0 0 138px;
  font-size: 25px;
}
.ruleBtn {
  color: #deb77a !important;
  margin-left: 20px;
  font-size: 25px;
}
.select_box {
  width: 32px;
  height: 32px;
  border: 1px solid #deb77a;
  opacity: 1;
  border-radius: 0px;
  margin-left: 166px;
}
.office_submit {
  width: 206px;
  height: 68px;
  margin: 10px auto 100px;
}
.office_submit img {
  width: 100%;
  height: 100%;
}
.office_submit {
  width: 278px;
  height: 98px;
  background: linear-gradient(180deg, #eed09d 0%, #e4c085 100%);
  box-shadow: 0px 0px 15px rgba(223, 183, 136, 0.35);
  opacity: 1;
  border-radius: 15px;
  color: #fff;
  font-size: 41px;
  text-align: center;
  line-height: 98px;
}
.signtureShow {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.signtureShow_box {
  width: 90%;
  height: 80%;
  background-color: #fff;
  margin: 150px auto 0;
  border-radius: 15px;
  padding-bottom: 20px;
}
.style_size .rerrm_member {
  color: skyblue;
  font-size: 25px;
  display: inline-block;
}
.pop_present {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.present_box {
  width: 600px;
  height: 700px;
  background: #ffffff;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin: 200px auto 0;
  border-radius: 10px;
  font-size: 25px;
  padding: 30px 30px;
  box-sizing: border-box;
}
.selectStatus1 {
  margin-top: 30px;
  overflow: scroll;
  height: 400px;
}
.radioClass {
  width: 543px;
  height: 80px;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  border: 1px solid #dedede;
  padding: 0 15px;
  box-sizing: border-box;
}
.item_rerr {
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: space-between;
  border-top: 1px solid #eee;
  margin-top: 0px;
}
.item_rerr div {
  flex: 1;
  text-align: center;
  line-height: 100px;
}
.confim {
  display: inline-block;
  width: 91px;
  height: 41px;
  background: #4eb84a;
  opacity: 1;
  color: #fff;
  text-align: center;
  line-height: 41px;
  margin-left: 15px;
  border-radius: 20px;
}
.cloneBtn {
  margin-left: 180px;
  font-size: 25px;
}
.referrerImg {
  width: 22px;
  height: 22px;
  margin-left: 10px;
}

.clone_box {
}

.van-dialog {
  width: 500px;
  height: 200px;
  font-size: 30px;
}

.van-dialog__message--has-title {
  font-size: 28px;
  margin-top: 20px;
}
.van-dialog__confirm,
.van-dialog__cancel {
  font-size: 30px !important;
}
</style>